<template>
  <div class="c-bottom-options" v-if="readOnly">
    <yos-button @click="onCancel">返回</yos-button>
  </div>
  <div class="c-bottom-options" v-else>
    <yos-button class="yos-button-primary" @click="onSave">{{ saveText }}</yos-button>
    <yos-button class="yos-button-danger" v-if="deleteText" @click="onDelete">{{ deleteText }}</yos-button>
    <yos-button @click="onCancel">{{ cancelText }}</yos-button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import YosButton from '../../components/form/yos-button'
export default {
  components: {
    YosButton
  },
  props: {
    saveText: {
      type: String,
      default: '保存'
    },
    deleteText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    readOnly: false
  },
  setup(props, {emit}) {
    const router = useRouter()

    const onCancel = () => {
      router.back()
    }

    const onSave = () => {
      emit('save')
    }

    const onDelete = () => {
      emit('delete')
    }

    return {
      onCancel,
      onSave,
      onDelete
    }
  }
}
</script>

<style lang="postcss">
.c-bottom-options {
  text-align: center;
  padding: 20px;

  .yos-button {
    margin: 0 10px;
  }
}
</style>
