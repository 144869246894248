<template>
  <div class="ui-bread-crumbs fix">
    <template v-for="(item, idx) in list">
      <span class="item" v-if="idx > 0">&ensp;&gt;&ensp;</span>
      <router-link
        class="item"
        v-if="item.to"
        :to="item.to"
      >
        {{item.name}}
      </router-link>
      <span v-else class="item on">{{item.name}}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="postcss">
.ui-bread-crumbs {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e0e1e2;
  font-size: 14px;
  margin-top: 18px;

  .item {
    color: #353535;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    display: inline-block;
    float: left;

    &.on {
      color: #1aad19;
    }
  }
}
</style>
