<template>
  <label :class="`yos-radio ${disabled ? 'yos-radio-disabled' : ''}`">
    <i :class="`icon-radio${value === modelValue ? '-checked' : ''}`" />
    <span class="yos-radio-label" v-if="label">{{label}}</span>
    <input
      class="yos-radio-input"
      type="radio"
      :checked="value === modelValue"
      :name="name"
      :disabled="disabled"
      @change="onChange"
    />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: [Boolean, Number, String],
    value: [Boolean, Number, String],
    label: '',
    name: '',
    disabled: false
  },
  setup (props, { emit }) {
    const onChange = () => {
      emit('update:modelValue', props.value)
    }

    return {
      onChange
    }
  }
}
</script>

<style lang="postcss">
.yos-radio {
  position: relative;
  display: inline-block;
  line-height: 18px;

  .icon-radio, .icon-radio-checked {
    vertical-align: top;
    font-size: 18px;
    color: #1aad19;
    margin-right: 3px;
    user-select: none;
  }

  .yos-radio-label {
    display: inline-block;
    vertical-align: top;
  }

  .yos-radio-input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.yos-radio-disabled {
    .icon-radio, .icon-radio-checked, .yos-radio-label {
      color: #999;
    }
  }
}
</style>
