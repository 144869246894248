<template>
  <div class="page-send-template">
    <h2 class="p-title">{{headerTitle}}</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card">
      <div class="card-title">模板信息</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">平台</span>
        <div class="right-wrapper">
          <select class="yos-select" v-model="platform">
            <option :value="1">纵购云集</option>
            <option :value="2">纵购</option>
          </select>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">&emsp;</span>
        <div class="right-wrapper">
          <yos-button class="yos-button-primary" @click="onChose">选择模板库模板</yos-button>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">模版编号</span>
        <div class="right-wrapper">{{ libraryId || '请选择模版' }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">模版标题</span>
        <div class="right-wrapper">{{ libraryTitle || '请选择模版' }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">状态</span>
        <div class="right-wrapper">
          <yos-radio name="status" v-model="status" :value="1" label="正常" />
          <yos-radio name="status" v-model="status" :value="0" label="禁用" />
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">成功推送</span>
        <div class="right-wrapper">{{ successTimes }}次</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">失败推送</span>
        <div class="right-wrapper">
          <span>
            {{ failOtherTimes + failUnfollowTimes }}次
            （因用户没关注公众号导致失败：{{ failUnfollowTimes }}次；其他失败原因：{{ failOtherTimes }}次）
          </span>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">关联公众号</span>
        <div class="right-wrapper">{{ num }}个</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed"></span>
        <div class="right-wrapper">
          <table
            cellspacing="0"
            class="m-table"
          >
            <thead>
              <tr>
                <th><yos-checkbox v-model="allCheckeds" @change="onAllChange" /></th>
                <th>公众号</th>
                <th>是否包含该模板</th>
                <th>累计推送用户数量（去重）</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in authorizers" :key="item.authorizerAppid">
                <td>
                  <yos-checkbox
                    v-model="checkeds[item.authorizerAppid]"
                    @change="onAppidChange"
                  />
                </td>
                <td>{{ item.nickName }}</td>
                <td>{{ libraryId ? (getTemplateByAppId(item.authorizerAppid) ? '是' : '否') : '未知' }}</td>
                <td>{{ count[item.authorizerAppid] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
      <bottom-options @save="onSave" />
    </div>
    <div :class="`yos-dialog library-dialog ${activeClass}`">
      <div class="mask" @click="onLibraryClose" />
      <div class="dialog-box">
        <i class="icon-close" @click="onLibraryClose" />
        <div class="title hairline-bottom">请选择模版</div>
        <div class="content hairline-bottom">
          <table
            cellspacing="0"
            class="m-table"
          >
            <thead>
              <tr>
                <th>选择</th>
                <th>编号</th>
                <th>标题</th>
                <th>备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataList" :key="item.id">
                <td><yos-radio name="libraryId" :value="item.id" v-model="libraryId" /></td>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.remark }}</td>
              </tr>
            </tbody>
          </table>
          <p v-if="!!libraryIdError">{{libraryIdError}}</p>
        </div>
        <div class="options">
          <yos-button
            class="yos-button-primary"
            @click="onLibraryChange"
          >
            确定
          </yos-button>
          <yos-button
            @click="onLibraryClose"
          >
            取消
          </yos-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import BreadCrumbs from '@/components/common/bread-crumbs'
import BottomOptions from '@/components/common/bottom-options'
import YosRadio from '@/components/form/yos-radio'
import YosDialog from '../../components/other/yos-dialog.vue'
import YosPager from '../../components/other/yos-pager'
import { ref, reactive, toRefs, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useServer, useTabNav, useToast, usePage } from '../../hook'
import {
  getTemplateLibraries,
  getSendTemplate,
  addSendTemplate,
  updateSendTemplate,
  getAuthorizers,
  getTemplateInfo
} from '../../data'
import { getPageNavs } from '../../util'

export default {
  components: {
    YosTabNav,
    BreadCrumbs,
    BottomOptions,
    YosRadio,
    YosDialog,
    YosPager
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params
    const headerTitle = ref('创建推送模板')
    const nav = getPageNavs('fun')
    nav[0].active = true
    const breadList = ref([{
      name: '推送模板',
      to: '/fun/send_templates'
    }])
    const state = reactive({
      id,
      status: 1,
      platform: 1,
      lastLibraryId: '',
      lastLibraryTitle: '',
      libraryId: '',
      libraryTitle: '',
      successTimes: 0,
      failOtherTimes: 0,
      failUnfollowTimes: 0,
      allCheckeds: false,
      selecteds: {},
      checkeds: {},
      authorizerAppids: '',
      authorizers: [],
      dataList: [],
      templates: [],
      totalPage: 0,
      libraryIdError: '',
      count: [],
      activeClass: ''
    })

    const num = computed(() => {
      return Object.keys(state.selecteds).filter(key => state.selecteds[key]).length
    })

    const { page, baseUrl } = usePage(`/fun/send_templates/${id >= 0 ? id : 'new'}`, { keyword: state.keyword })

    const success = ({ msg }) => {
      toast(msg)
      router.back()
    }

    const onAppidChange = () => {
      const arr = Object.keys(state.checkeds)
      const checkedSize = arr.filter(key => state.checkeds[key]).length
      state.allCheckeds = checkedSize > 0 && checkedSize === arr.length
      arr.forEach(key => {
        state.selecteds[key] = state.checkeds[key]
      })
    }

    const onAllChange = () => {
      const checkeds = {}
      const selecteds = {...state.selecteds}
      Object.keys(state.checkeds).forEach(key => {
        checkeds[key] = state.allCheckeds
        selecteds[key] = state.allCheckeds
      })
      state.checkeds = checkeds
      state.selecteds = selecteds
    }

    const firstSuccess = () => {
      const checkeds = {}
      const selecteds = {}

      if (id ? (state.name && state.authorizers.length > 0) : state.authorizers.length > 0) {
        if (id && state.authorizerAppids) {
          state.authorizerAppids.split(',').forEach(item => {
            selecteds[item] = true
          })
        }
        state.authorizers.forEach(item => {
          const checked = state.authorizerAppids.indexOf(item.authorizerAppid) !== -1
          checkeds[item.authorizerAppid] = checked
          selecteds[item.authorizerAppid] = checked
        })

        state.checkeds = checkeds
        state.selecteds = selecteds
      }
    }

    const getTemplateByAppId = (authorizerAppid) => {
      return state.templates.find(item => item.authorizerAppid === authorizerAppid)
    }

    const setTemplateLibrariesParams = useServer(getTemplateLibraries, ({ dataList }) => {
      state.dataList = dataList
      state.activeClass = 'open'
    })

    const setTemplateInfoParams = useServer(getTemplateInfo, ({ dataList, count }) => {
      const checkeds = {}
      state.authorizerAppids = Object.keys(count).filter(key => count[key]).join(',')
      state.count = count
      state.templates = dataList
      state.authorizers.forEach(item => {
        if (item.authorizerAppid) { // && getTemplateByAppId(item.authorizerAppid)) {
          checkeds[item.authorizerAppid] = state.checkeds[item.authorizerAppid] || false
        }
      })
      state.checkeds = checkeds
      firstSuccess()
      onAppidChange()
    })

    const onLibraryChange = () => {
      const current = state.dataList.find(item => item.id === state.libraryId)
      const authorizerAppids = state.authorizers.map(item => item.authorizerAppid).join(',')

      state.lastLibraryId = state.libraryId
      state.libraryTitle = current.title
      state.activeClass = ''
      setTemplateInfoParams({libraryId: state.libraryId, authorizerAppids})
    }

    const setSendTemplateParams = useServer(getSendTemplate, ({ status, platform, libraryId, libraryTitle, successTimes, failOtherTimes, failUnfollowTimes, authorizerAppids }) => {
      state.status = status
      state.platform = platform
      state.lastLibraryId = libraryId
      state.lastLibraryTitle = libraryTitle
      state.libraryId = libraryId
      state.libraryTitle = libraryTitle
      state.successTimes = successTimes
      state.failOtherTimes = failOtherTimes
      state.failUnfollowTimes = failUnfollowTimes
      authorizerAppids.split(',').forEach(item => {
        item && (state.checkeds[item] = true)
      })

      onAppidChange()

      if (id && state.authorizers.length > 0) {
        setTemplateInfoParams({libraryId: state.libraryId, authorizerAppids: state.authorizers.map(item => item.authorizerAppid).join(',')})
      }
    })


    const setAuthorizersParams = useServer(getAuthorizers, ({ authorizers = [], totalPage }) => {
      const checkeds = {}
      const authorizerAppids = authorizers.map(item => item.authorizerAppid).join(',')
      state.authorizers = authorizers
      state.totalPage = totalPage

      if (Object.keys(state.selecteds).length === 0) {
        firstSuccess()
      } else {
        authorizers.forEach(item => {
          const checked = state.authorizerAppids.indexOf(item.authorizerAppid) !== -1
          checkeds[item.authorizerAppid] = state.selecteds[item.authorizerAppid] !== undefined ? state.selecteds[item.authorizerAppid] : checked
          state.selecteds[item.authorizerAppid] = checkeds[item.authorizerAppid]
        })
        state.checkeds = checkeds
      }

      onAppidChange()

      if (id && state.libraryId) {
        setTemplateInfoParams({libraryId: state.libraryId, authorizerAppids})
      }
    })

    const setAddSendTemplateParams = useServer(addSendTemplate, success)
    const setUpdateSendTemplateParams = useServer(updateSendTemplate, success)

    const onPageChange = (page) => {
      setAuthorizersParams({keyword: state.keyword, status: state.status, page})
    }

    const onChose = () => {
      setTemplateLibrariesParams({})
    }


    const onLibraryClose = () => {
      state.libraryId = state.lastLibraryId
      state.libraryTitle = state.libraryTitle
      state.activeClass = ''
    }

    const onSave = () => {
      const authorizerAppids = Object.keys(state.selecteds).filter(key => state.selecteds[key]).join(',')
      const params = { platform: state.platform, libraryId: state.libraryId, libraryTitle: state.libraryTitle, status: state.status, authorizerAppids }

      if (id) {
        setUpdateSendTemplateParams({ id, ...params })
      } else {
        setAddSendTemplateParams(params)
      }
    }

    setAuthorizersParams({})

    if (id >= 0) {
      setSendTemplateParams({ id })
      headerTitle.value = '编辑推送模板'
    }

    breadList.value.push({ name: headerTitle.value })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      num,
      headerTitle,
      breadList,
      onAppidChange,
      onAllChange,
      page,
      baseUrl,
      onPageChange,
      onChose,
      getTemplateByAppId,
      onSave,
      onLibraryChange,
      onLibraryClose
    }
  }
}
</script>

<style lang="postcss">
.page-send-template {
  .left-fixed-item .left-fixed {
    width: 95px;
  }

  .right-wrapper {
    margin-left: 95px;
  }

  .yos-dialog.library-dialog {
    .dialog-box {
      width: 800px;
    }

    .content {
      padding-top: 0;
    }
  }
}
</style>
